import React from 'react'
import './salesProfile.css'
// import NavbarComp from '../../components/Navbar/Navbar'

const SalesProfile = () => {
  return (
    <div className="profilePage">
        {/* <NavbarComp/> */}
        {/* <SalesSidebar/> */}
        <div className='content'>
           Welcome To World of AI
        </div>
    </div>
  )
}

export default SalesProfile