// Hero.js

import React, { useState } from "react";
import "./hero.css";
import AnimatedLogo from "../AnimatedLogo/AnimatedLogo";
import { Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

const Hero = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigate = useNavigate();

  const removeDataFromLocalStorage = () => {
    localStorage.removeItem("email");
    navigate("/");
    window.location.reload();
  };

  const email = localStorage.getItem("email");

  return (
    <>
      <div className="hero-banner">
        <div className="banner-content">
          <p>
            <b>
              Vidyam AI Proudly Announces Vidyam LLM – Transform Your Learning
              Experience with the First in Series Aligned with NCERT Syllabus
              and Grade Levels.{" "}
            </b>{" "}
            <Link className="banner-btn" to="/blog/vidyam-llm">
              Learn More{" "}
            </Link>
          </p>
        </div>
      </div>
      <div className="home-hero">
        <div className="hero-container">
          <div className="navbar">
            <div className="logo">
              <AnimatedLogo color="white" />
            </div>
            <div className="nav-items">
              <ul>
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#team">Team</a>
                </li>
                <li>
                  <a href="#career">Career</a>
                </li>
                <li>
                  <a href="#contact">Contact us</a>
                </li>
              </ul>
            </div>

            <div className="login-btn login-btn-full">
              {email ? (
                email === "team@vidyamai.com" ? (
                  <Link to="/magic">
                    <CgProfile className="profileIcon" />
                  </Link>
                ) : email === "sales@vidyamai.com" ? (
                  <Link to="/sales-magic-chat">
                    <CgProfile className="profileIcon" />
                  </Link>
                ) : (
                  <Link to="/login">
                    <button className="home-login buttonFlat">Login</button>
                  </Link>
                )
              ) : (
                <Link to="/login">
                  <button className="home-login buttonFlat">Login</button>
                </Link>
              )}
              {email ? (
                <button
                  className="buttonFlat"
                  style={{ marginLeft: "15px" }}
                  onClick={removeDataFromLocalStorage}
                >
                  Log Out
                </button>
              ) : (
                <div className="profile-placeholder"></div>
              )}
            </div>
            <div className="hamburger-menu" onClick={toggleMenu}>
              {isMenuOpen ? (
                <AiOutlineClose className="cross-button" />
              ) : (
                <>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </>
              )}
            </div>
          </div>

          <div className={`menu ${isMenuOpen ? "open" : ""}`}>
            <ul>
              <li>
                <a href="#about" onClick={toggleMenu}>
                  About us
                </a>
              </li>
              <li>
                <a href="#team" onClick={toggleMenu}>
                  Team
                </a>
              </li>
              <li>
                <a href="#career" onClick={toggleMenu}>
                  Career
                </a>
              </li>
              <li>
                <a href="#contact" onClick={toggleMenu}>
                  Contact us
                </a>
              </li>
              <div className="login-btn">
                {email ? (
                  email === "team@vidyamai.com" ? (
                    <Link to="/magic">
                      <CgProfile className="profileIcon" />
                    </Link>
                  ) : email === "sales@vidyamai.com" ? (
                    <Link to="/sales-magic-chat">
                      {/* <CgProfile className="profileIcon" /> */}
                    </Link>
                  ) : (
                    <Link to="/login">
                      <button className="home-login buttonFlat">Login</button>
                    </Link>
                  )
                ) : (
                  <Link to="/login">
                    <button className="home-login buttonFlat">Login</button>
                  </Link>
                )}
                {email ? (
                  <button
                    className="buttonFlat"
                    style={{ marginLeft: "15px" }}
                    onClick={removeDataFromLocalStorage}
                  >
                    Log Out
                  </button>
                ) : (
                  <div className="profile-placeholder"></div>
                )}
              </div>
            </ul>
          </div>
          <div className="hero-body">
            <div className="logo-body">
              <img
                src="/images/VidyamAI-top.png"
                className="logo-top"
                alt="logo-top"
              ></img>
              <p className="logo-name">Vidyam AI</p>
              <img
                src="/images/VidyamAI-bottom.png"
                className="logo-bottom"
                alt="logo-bottom"
              ></img>
            </div>
            <p>
              AI <span>research</span> and <span>products</span> that <br></br>
              transform the education and society
            </p>
          </div>
          <div className="banner-and-video">
            <div className="qr-image-container">
            <p>Get instant homework help, clear doubts, and test knowledge with quizzes.</p>
              <a
                href="https://wa.me/message/42QFKGNKKU3NN1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/vidyam-ai-qr.jpeg" alt="vidyam-ai-qr"></img>
              </a>
              <p><b>Start Studying on WhatsApp (Class 6 to 12)</b> </p>
              {/* <p>Get instant help with your homework, clear your doubts, and test your knowledge with quizzes.</p> */}
              
            </div>
            <div className="video-container">
            <p>Watch our demo video to learn about Saarthi. Discover how our Student AI Co-Pilot aids studies and boosts career success.</p>
            <iframe
              src="https://www.youtube.com/embed/F7mvgKv-6I4?rel=0"
              frameborder="0"
              allow="autoplay; encrypted-media; fullscreen"
              allowfullscreen
              title="video"
            ></iframe>
            <p><b>Saarthi Platform Introduction</b></p>
           
           </div>
          </div>
     
        </div>
      </div>
    </>
  );
};

export default Hero;
