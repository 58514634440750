import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import "./accordion.css"
function MissionAccordion() {
  return (
    <Accordion className='mission-accordion-container' >
      <Accordion.Item eventKey="0">
        <Accordion.Header className='mission-accordion-header'style={{fontSize:"1.5rem"}}>Models</Accordion.Header>
        <Accordion.Body className='mission-accordion-body'>
        <h5>Vidyam LLMs: Precision Learning for Every Grade</h5>

General-purpose LLMs like ChatGPT often miss the mark in delivering precise educational content tailored to specific curricula. Vidyam LLM bridges this gap by providing clear, curriculum-aligned responses for every grade level. Whether you're in class 6 or class 12, Vidyam LLM ensures that you receive explanations that are directly aligned with your textbooks and syllabus.

Discover More: Dive deeper into how Vidyam LLMs are transforming education by reading our latest blog post - <Link style={{color:"#fff",textDecoration: "underline"}} to="/blog/vidyam-llm">Introducing Vidyam LLM</Link>.
          
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header className='mission-accordion-header'>Platforms</Accordion.Header>
        <Accordion.Body className='mission-accordion-body'>
        Our team at Vidyam AI is dedicated to expanding the Saarthi platform (co-pilot) to support students in various domains including engineering, medical, law, and more, ensuring comprehensive educational support for all learners.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header className='mission-accordion-header'>Products</Accordion.Header>
        <Accordion.Body className='mission-accordion-body'>
        At Vidyam AI, we're dedicated to reshaping education with cutting-edge AI technologies. Alongside our core mission, we've developed two revolutionary products: Magic Email and Magic Doc, powered by GenAI-based technologies.
        <br></br>
        <br></br>
        <b>Magic Email:</b> Transform your email management with Magic Email. Our advanced solution comprehends virtually any type of content, facilitating seamless workflow execution. Whether it's processing emails for flight bookings, car bookings, or hotel bookings from your customers, Magic Email streamlines customer interactions and enhances service delivery. 
        <br></br>
        <br></br>
        <b>Magic Doc:</b> Say goodbye to manual document processing with Magic Doc. Leveraging GenAI-based technologies, this innovative tool extracts valuable information from diverse documents, including invoices, contracts, and reports. Streamline your workflows, reduce manual efforts, and optimize efficiency across your organisation.
        <br></br>
        <br></br>
        Contact us at hello@vidyamai.com or 🇮🇳 7-678910-123 to explore customized solutions tailored to your specific usecase and to discuss pricing options.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default MissionAccordion;