import { React } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import AnimatedLogo from "../AnimatedLogo/AnimatedLogo";
import { useNavigate } from "react-router-dom";


const NavbarComp = () => {
  
  const email = localStorage.getItem("email");

  const navigate = useNavigate();

  const removeDataFromLocalStorage = () => {
    // Remove the data from local storage

    localStorage.removeItem("email");
    navigate("/");
    window.location.reload();

    // Update the state if needed
    // setData(null);

    // Optionally, you can perform additional actions after removing the data
    // console.log('Data removed from local storage.');
    // navigate(0)
  };

  return (
    <div className="home-header">
      <header
        data-thq="thq-navbar"
        className="navbarContainer home-navbar-interactive"
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          {/* <span className="logo">DimensionZero</span> */}
          <AnimatedLogo style={{ color: "#333" }} />
        </Link>
        <div data-thq="thq-navbar-nav" className="home-desktop-menu">
          <nav className="home-links">
            <Link
              to="/"
              style={{ textDecoration: "none", color: "black", opacity: "0.9" }}
            >
              <span className="home-nav12 bodySmall">Home</span>{" "}
            </Link>
          </nav>
          <div className="home-buttons">
            {email === "team@vidyamai.com" ? (
              <Link to="/magic">
                <CgProfile className="profileIcon" />
              </Link>
            ) : email === "sales@vidyamai.com" ? (
              <Link to="/sales-magic-chat">
                <CgProfile className="profileIcon" />
              </Link>
            ) : null}
            {email ? (
              <button
                className="buttonFlat log-out-btn"
                style={{ marginLeft: "15px" }}
                onClick={removeDataFromLocalStorage}
              >
                Log Out
              </button>
            ) : (
              " "
            )}
          </div>
        </div>
        
      </header>
    </div>
  );
};

export default NavbarComp;
