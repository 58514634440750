import React from "react";
import "./homeBody.css";
import FooterComp from "../Footer/Footer";

import MissionAccordion from "../Accordion/Accordion";
const HomeBody = () => {
  return (
    <>
      <div className="homeBody">
        <div className="homeBody-container">
          <div id="about" className="mission">
            <div className="mission-content">
              <h1>
                Our Mission
                <span class="material-symbols-outlined">task_alt</span>
              </h1>
              <p>
                Our mission at Vidyam AI is to redefine education by creating
                cutting-edge technologies like GenAI, XR, and the Saarthi app,
                personalized for every student in India and beyond. We're
                committed to bridging the gap between traditional education and
                immersive learning experiences.
              </p>
            </div>
            <div className="mission-accordion">
              <MissionAccordion />
            </div>
          </div>
          <div id="team" className="team">
            <div className="team-content">
              <h1>Our Team</h1>
              <p>
              Our team at Vidyam AI comprises seasoned professionals with extensive experience in AI research and product development. With backgrounds spanning over 15 years in technology, our co-founders bring a wealth of expertise to the table. From AI cloud services to data science and product management, our diverse skill sets converge to drive innovation and redefine the educational landscape.
              </p>
            </div>
          </div>
          <div id="career" className="hiring">
            <div className="hiring-content">
              <div className="hiring-image-container">
                <img src="/images/hiring (1).png" alt="hiring"></img>
                <div className="text">
                  WE <br></br> ARE <br></br> HIRING...
                </div>
              </div>
              <div className="hiring-content-body">
                <p>
                  We are hiring for various roles across functions. If you're
                  driven by innovation and thrive in a collaborative, fast-paced
                  setting, we'd love to hear from you at{" "}
                  <b>hello@vidyamai.com</b>
                </p>
              </div>
            </div>
          </div>
          <div id="contact" className="contact-us">
            <div className="contact-content">
              <div>
                <p>Want to know more</p>
                <h1>Let's Talk</h1>
              </div>
              <div className="contact-info">
                <p>E-Mail: <span>hello@vidyamai.com</span></p>
                <p>Call: <span>🇮🇳 7-6789-10-123</span></p>
                <h2>
                  Join hands to embrace<br></br>
                  AI revolution{" "}
                </h2>
              </div>
            </div>
          </div>
          <FooterComp />
        </div>
      </div>
    </>
  );
};

export default HomeBody;
