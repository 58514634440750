import React, { useEffect, useState } from "react";
import SidebarCom from "../../../components/Sidebar/Sidebar";
import "./userDataExpose.css";
import "./../../../style.css";
import { supabase } from "../../../supabaseClinet";
import { fetchData } from "../../../components/DownloadExcel/DownloadExcel";
import { exportToExcel } from "../../../components/DownloadExcel/DownloadExcel";

const UserDataExpose = () => {
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterName, setFilterName] = useState("");

  const [hasMoreData, setHasMoreData] = useState(true);
  const itemsPerPage = 10;

  const handleExport = async () => {
    const tableName = "user_data";
    const data = await fetchData(tableName);
    exportToExcel(data);
  };

  const handleFilterNameChange = (event) => {
    setFilterName(event.target.value); // Update filterName state
    setCurrentPage(1); // Reset to first page when filter changes
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        const { data, error } = await supabase.rpc('filter_user_data', {
          name_filter: filterName ? filterName : null,
          limit_count: itemsPerPage,
          offset_count: (currentPage - 1) * itemsPerPage

        });

        if (error) throw error;

        if (data != null) {
          setUserData(data);
          console.log(data.length);
          setHasMoreData(data.length < 10); // If less than 10, there is no more data
        }
      } catch (error) {
        alert(error.message);
      }
    };

    getUserData();
  }, [currentPage,filterName]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // console.log(userData);

  return (
    <div className="user-data-expose">
      <SidebarCom />
      <div className="content">
        <div class="header">
          <h2>User Data</h2>
          <button className="buttonFlat" onClick={handleExport}>
            Export to Excel
          </button>
        </div>
        <div className="filters">
          <h3>Filters:</h3>
          <div className="filter-options">
            <label>
              Filter by Name or Number or Address:
              <input
                type="text"
                value={filterName}
                onChange={handleFilterNameChange}
              />
            </label>
          </div>
        </div>

        <div>
          <table>
            <thead>
              <tr>
                <th>Time Stamp</th>
                <th>Mobile No.</th>
                <th>Role</th>
                <th>Language</th>
                <th>Name</th>
                <th>Age</th>
                <th>School</th>
                <th>Grade</th>
                <th>Address</th>
                <th>Disclaimer</th>
                <th>Verified</th>
              </tr>
            </thead>
            <tbody>
              {userData?.map((item, key) => (
                <tr key={key}>
                  <td>
                    {new Date(item.created_at).toLocaleString("en-US", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </td>
                  <td>{item.mobile_no}</td>
                  <td>{item.role}</td>
                  <td>{item.language}</td>
                  <td>{item.name}</td>
                  <td>{item.age}</td>
                  <td>{item.school}</td>
                  <td>{item.grade}</td>
                  <td>{item.address}</td>
                  <td>{item.disclaimer}</td>
                  <td>{item.verified}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <button
            class="buttonFlat"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>{`Page ${currentPage}`}</span>
          <button
            class="buttonFlat"
            onClick={handleNextPage}
            disabled={hasMoreData}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserDataExpose;
