
import neo4j from 'neo4j-driver';

//prod
const driver = neo4j.driver(
    'neo4j+s://0aaf7946.databases.neo4j.io',
    neo4j.auth.basic('neo4j', 'pM8ZA8RdUOiivVlnOIhMi9oWawvNF_dFtcBIH6ktmHM')
  );


//dev
// const driver = neo4j.driver(
//     'neo4j+s://cbff9fe2.databases.neo4j.io',
//     neo4j.auth.basic('neo4j', 'prBlGZ7JS-nFcBaGZs0HHMQ6oG_rxiArm16YJXcC5sI')
//   );

export {driver};


