// Footer.js

import React from "react";
import "./footer.css"; // Import the CSS module

const FooterComp = () => {
  return (
    <>
      <footer>
        <div className="footer-content">
          <p>
            © 2024 Vidyam AI (Vidyam AI Global Tech Solution Pvt. Ltd.) All
            rights reserved.
          </p>
        </div>
        <div className="footer-all-links">
          <a
            href="https://www.linkedin.com/company/vidyamai"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            <i class="fa fa-linkedin-square" aria-hidden="true"></i>
          </a>
          <a
            href="https://www.youtube.com/@VidyamAI"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-link"
          >
            <i class="fa fa-youtube-play" aria-hidden="true"></i>
          </a>
        </div>
      </footer>
    </>
  );
};

export default FooterComp;
