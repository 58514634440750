// AnimatedLogo.js
import React, { useEffect, useState } from 'react';
import styles from './AnimatedLogo.module.css';

const AnimatedLogo = ({ color }) => {
  const [scrollDirection, setScrollDirection] = useState('down');

  useEffect(() => {
    let lastScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (currentScrollPos > lastScrollPos) {
        setScrollDirection('down');
      } else if (currentScrollPos < lastScrollPos) {
        setScrollDirection('up');
      }

      lastScrollPos = currentScrollPos;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const animate = () => {
    const tiles = document.querySelectorAll(`.${styles.tile}`);
    const title = document.querySelector(`.${styles.title}`);

    tiles.forEach((t) => t.classList.remove(styles.animate));
    title.classList.remove(styles.animate);

    setTimeout(() => {
      tiles.forEach((t) => t.classList.add(styles.animate));
      title.classList.add(styles.animate);
    }, 500);
  };

  useEffect(() => {
    animate();
  }, [scrollDirection]);

  return (
    <div className={styles.logo}>
      <h1 className={`${styles.title} ${scrollDirection === 'down' ? styles.animate : ''}`} style={{ color }}>
      Vidyam AI
      </h1>
      <ul>
        <li className={`${styles.tile} ${scrollDirection === 'down' ? styles.animate : ''}`} style={{ backgroundColor: color }}></li>
        <li className={`${styles.tile} ${scrollDirection === 'down' ? styles.animate : ''}`} style={{ backgroundColor: color }}></li>
        <li className={`${styles.tile} ${scrollDirection === 'down' ? styles.animate : ''}`} style={{ backgroundColor: color }}></li>
      </ul>
    </div>
  );
};

export default AnimatedLogo;
